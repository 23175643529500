import React from "react"
import styled from "styled-components"
import UnstyledLink from "@components/elements/UnstyledLink"
import { Box } from "@material-ui/core"

const StyledBox = styled(Box)`
  color: #2a2a2a;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
  text-align: center;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;

  @media (max-width: 1023px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    border-top: 0;
  }
  .title {
    margin-top: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #4a4545;
    @media (max-width: 1023px) {
      margin-top: 0;
      font-size: 14px;
      text-align: left;
    }
  }
  img {
    width: auto;
    height: 100px;
    padding-bottom: 12px;
    @media (max-width: 1023px) {
      height: 32px;
      width: auto;
      padding-right: 16px;
      padding-bottom: 0;
    }
  }
`

const CareerTrackBox = ({ name, slug, icon, to }) => {
  return (
    <StyledBox height={"100%"} p={4} position="relative">
      <img src={icon} alt="icon" />
      <Box className="title">{name}</Box>
      <Box
        component={UnstyledLink}
        to={to}
        position="absolute"
        width={1}
        height={1}
        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
      ></Box>
    </StyledBox>
  )
}

export default CareerTrackBox
