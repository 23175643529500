import React from "react"
import styled from "styled-components"

import { Box } from "@material-ui/core"

import LandingBackground from "@assets/landing-bg.jpg"
import AppLayout from "@components/layout/App"
import CareerTrackBox from "@components/elements/CareerTrackBox"
import SearchTypeahead from "@components/modules/SearchTypeahead"
import UnstyledLink from "@components/elements/UnstyledLink"

import MENU from "@constants/menu.es"

const FIRST_LEVEL_MENU = MENU.FIRST_LEVEL_MENU

const HomeContainer = styled.div`
  height: 100%;

  @media (max-width: 1023px) {
    padding: 2rem 0;
  }
`

const BackgroundImageDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${LandingBackground});
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 1;
`
const ContentWrapper = styled.div`
  color: ${props => props.theme.colors.textBlack};
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    z-index: 2;
  }
`

const PageTitle = styled.div`
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`

const PageSubtitle = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 28px;

  margin-bottom: 2rem;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 1000px;
  max-width: 1000px;

  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
  }
`

const SearchFlexContainer = styled(FlexContainer)`
  flex-wrap: nowrap;
  align-items: center;

  // padding: 0.5rem;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 16px;

  .three-box {
    grid-auto-columns: 33.3%;
  }

  .four-box {
    grid-auto-columns: 25%;
  }
`

const InnerGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`

const SpecializationBox = styled(Box)`
  background: ${props => props.theme.colors.boxGreen};

  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
  text-align: center;
  border: none;

  width: 100%;
  height: 100%;
  margin: auto;

  display: table;
`

const SpecializationTextBox = styled(Box)`
  display: table-cell;
  vertical-align: middle;

  color: ${props => props.theme.colors.textBlack};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`

export default function Home() {
  return (
    <AppLayout noHeader={true}>
      <HomeContainer>
        <BackgroundImageDiv />

        <ContentWrapper>
          <PageTitle>Career Visualization Tool</PageTitle>
          <PageSubtitle>
            Career Framework for Abu Dhabi Executive Office
          </PageSubtitle>

          <SearchFlexContainer style={{ marginBottom: "1rem" }}>
            <SearchTypeahead style={{ width: "100%" }} grey={false} />
          </SearchFlexContainer>

          <FlexContainer>
            <GridContainer>
              {FIRST_LEVEL_MENU.map((careerTrack, index) => (
                // <UnstyledLink
                //   to={careerTrack.to}
                //   key={`first-level-menu-box-${index}`}
                // >
                <CareerTrackBox
                  {...careerTrack}
                  key={`career-track-box-${index}`}
                />
                // </UnstyledLink>
              ))}

              {FIRST_LEVEL_MENU.map((careerTrack, index) => (
                <InnerGridContainer key={`first-level-menu-inner-${index}`}>
                  {careerTrack.specializations.map((sp, spIndex) => (
                    <UnstyledLink
                      to={sp.to}
                      key={`first-level-menu-children-link-${spIndex}`}
                    >
                      <SpecializationBox
                        p={2}
                        my={2}
                        className={
                          careerTrack.specializations.length === 3
                            ? "three-box"
                            : "four-box"
                        }
                      >
                        <SpecializationTextBox>{sp.name}</SpecializationTextBox>
                      </SpecializationBox>
                    </UnstyledLink>
                  ))}
                </InnerGridContainer>
              ))}
            </GridContainer>
          </FlexContainer>
        </ContentWrapper>
      </HomeContainer>
    </AppLayout>
  )
}
